html,
body,
#root,
.container {
  height: 100%;
  overflow: hidden;
}

body {
  font-family: monospace;
  margin: 0;
  background: #000;
  color: white;
}

.container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto 1fr auto;
  column-gap: 7px;
}

.header {
  grid-column-start: 1;
  grid-column-end: -1;
  padding: 5px;
}

.footer {
  grid-column-start: 1;
  grid-column-end: -1;
}

.container > .panel {
  height: 100%;
  overflow: auto;
}

.output {
  margin: 0;
  padding: 10px;
  box-sizing: border-box;
  white-space: pre-wrap;
}

.error {
  color: darkred;
  background: white;
  padding: 5px;
  margin-left: 5px;
}

.selector {
  box-sizing: border-box;
  width: 100%;
  background: #222;
  color: white;
  border: 1px solid #aaa;
  padding: 5px;
  font-size: 16px;
}

.clear-button {
  background: #222;
  color: #eee;
  border: 1px solid #aaa;
  border-radius: 2px;
  padding: 5px;
  margin: 10px;
}

@media (max-width: 800px) {
  .container {
    grid-template-columns: none;
  }
}
